<template>
  <!-- 省市区管理 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="btn-group">
      <div></div>
      <!--      <div>-->
      <!--        <el-button type="primary" size="mini" icon="el-icon-plus" @click="$router.push({ name: 'AreaAdd', params: { parent_id: 0 } })"-->
      <!--          >新增</el-button-->
      <!--        >-->
      <!--      </div>-->
    </div>
    <el-table
      :data="list"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :header-row-style="{ height: '50px', color: '#626e7c' }"
      :header-cell-style="{ background: '#f8f9fb', padding: '8px 0' }"
      :cell-style="changeTr"
      default-expand-all
    >
      <el-table-column label="名称" align="left" width="350px">
        <template slot-scope="{ row }">
          {{ row.name }}
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link :underline="false" v-show="scope.row.level !== 4" type="primary" @click="goAdd(scope.row)">新增</el-link>
          <el-link :underline="false" v-show="scope.row.pid !== 0" style="margin: 0 10px" type="primary" @click="goEdit(scope.row)">编辑</el-link>
          <el-link :underline="false" v-show="scope.row.pid !== 0" type="primary" @click="apiDelMenu(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getMenusTree, delMenu } from './api'

export default {
  name: 'Index',

  data() {
    return {
      list: [],
      total: 0
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.apiQueryMenuTree()
  },
  methods: {
    // 新增子级
    goAdd(row) {
      const { id } = row
      this.$router.push({
        name: 'AreaAdd',
        params: {
          parent_id: id
        }
      })
    },
    goEdit(row) {
      const { id } = row
      this.$router.push({ name: 'AreaEdit', params: { menuId: id } })
    },
    async apiQueryMenuTree() {
      let res = await getMenusTree()
      this.list = res || []
    },

    async apiDelMenu(id) {
      this.$confirm('此操作将永久删除该地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delMenu(id).then(() => {
            this.apiQueryMenuTree()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 修改第一列无children的样式
    changeTr({ row, column, rowIndex, columnIndex }) {
      if (!row.children && columnIndex == 0) {
        return { paddingLeft: '23px' }
      }
    },
    handleClick(val) {
      this.apiQueryMenuTree()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  /deep/.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  // .el-table /deep/ {
  //   border-top-left-radius: 5px;
  //   border-top-right-radius: 5px;
  // }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
